// src/AdminPages/AdminLogin.js

import React, { useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebaseConfig"; // Adjust this path based on your setup

const AdminLogin = ({ onLogin }) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message

    try {
      const docRef = doc(db, "Info", "Admin");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const adminData = docSnap.data();
        if (adminData.Name === name && adminData.Password === password) {
          onLogin(true); // Successful login
        } else {
          setError("Invalid name or password.");
        }
      } else {
        setError("Admin data not found.");
      }
    } catch (error) {
      setError("Error fetching admin data: " + error.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[90vh]">
      <h2 className="text-3xl mb-4">Admin Login</h2>
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleLogin} className="flex flex-col gap-4">
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="border p-2 rounded"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="border p-2 rounded"
        />
        <button type="submit" className="bg-blue-500 text-white py-2 rounded">
          Login
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
