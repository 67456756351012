import React from "react";
import hotelsideview from "../assets/aboutimages/hotelsideview.jpg"
import hoteldashview from "../assets/aboutimages/hoteldashboard.jpg"
import hotelfrontview from "../assets/aboutimages/hotelfrontview.jpg"

export default function AboutCard() {
  return (
    <>
    <div className="flex justify-between items-center md:h-[50vh] w-full md:px-[15vw] px-[10vw] my-[10vh] flex-col md:flex-row">
      <div className="md:w-[38vw] md:h-full mb-[2.2vh] w-full flex flex-col justify-evenly items-start">
        <h1 className="text-teal-500 text-[2vh]" >About Us</h1>
        <h1 className="text-neutral-900 font-bold text-[4vh] tracking-wider">
          Welcome To
          <br />
          Hotel Rainbow
        </h1>
        <p className="text-neutral-900 text-[1.8vh] pe-[3vw]" >
        Hotel Rainbow is a vibrant and welcoming destination offering comfortable accommodations, modern amenities, and personalized service. Whether you're here for a relaxing getaway or a business trip, Hotel Rainbow provides a unique blend of luxury and convenience, ensuring a memorable stay. Enjoy stylish rooms, excellent dining, and easy access to local attractions, all in a warm and inviting atmosphere.
        </p>
        <h1 className="text-[2.5vh] font-bold text-neutral-900" >
          Manager :{" "}
          <span className="text-teal-500 font-normal">Manager Name</span>
        </h1>
      </div>
      <div className="md:w-[38vw] w-full md:h-full h-[49vh] flex justify-evenly items-start gap-[1vh]" >
        <div className="flex flex-col gap-[1vh]">
          <div className="md:w-[18vw] md:h-[24.5vh] w-[40vw]  h-[19.5vh] rounded-md relative overflow-hidden">
          <img
            className="md:w-[18vw] md:h-[24.5vh] w-[40vw]  h-[19.5vh] rounded-md object-cover hover:scale-[1.02] transition-all ease-in-out absolute top-0"
            src={hoteldashview}
          />
          </div>
          <div className="md:w-[18vw] md:h-[24.5vh] w-[40vw]  h-[19.5vh] rounded-md relative overflow-hidden">
          <img
            className="md:w-[18vw] md:h-[24.5vh] w-[40vw]  h-[19.5vh] rounded-md object-cover hover:scale-[1.02] transition-all ease-in-out absolute top-0"
            src={hotelsideview}
          />
          </div>
        </div>
        <div
        className="md:w-[18vw] md:h-[50vh] w-[40vw] h-[40vh] object-cover rounded-md relative overflow-hidden">
        <img
          className="md:w-[18vw] md:h-[50vh] w-[40vw] h-[40vh] object-cover rounded-md hover:scale-[1.02] transition-all ease-in-out absolute top-0"
          src={hotelfrontview}
        />
        </div>
      </div>
    </div>
    <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-[2vw] px-[18vw] my-[10vh]">
        <div className="h-[20vh] w-auto  flex flex-col justify-evenly items-center text-neutral-800 font-bold text-[3vh] rounded-md shadow-md  shadow-neutral-300 hover:scale-95 transition-all ease-in-out">
            <i class="fa-solid fa-wifi text-[5vh] text-teal-500"></i>
            <h1>Wifi</h1>
        </div>
        <div className="h-[20vh] w-auto  flex flex-col justify-evenly items-center text-neutral-800 font-bold text-[3vh] rounded-md shadow-md  shadow-neutral-300 hover:scale-95 transition-all ease-in-out">
            <i class="fa-solid fa-utensils text-[5vh] text-teal-500"></i>
            <h1>Food</h1>
        </div>
        <div className="h-[20vh] w-auto  flex flex-col justify-evenly items-center text-neutral-800 font-bold text-[3vh] rounded-md shadow-md  shadow-neutral-300 hover:scale-95 transition-all ease-in-out">
            <i class="fa-solid fa-car text-[5vh] text-teal-500"></i>
            <h1>Car Rental</h1>
        </div>
        <div className="h-[20vh] w-auto  flex flex-col justify-evenly items-center text-neutral-800 font-bold text-[3vh] rounded-md shadow-md  shadow-neutral-300 hover:scale-95 transition-all ease-in-out">
            <i class="fa-solid fa-tv text-[5vh] text-teal-500"></i>
            <h1>TV</h1>
        </div>
        <div className="h-[20vh] w-auto  flex flex-col justify-evenly items-center text-neutral-800 font-bold text-[3vh] rounded-md shadow-md  shadow-neutral-300 hover:scale-95 transition-all ease-in-out">
            <i class="fa-solid fa-bell-concierge text-[5vh] text-teal-500"></i>
            <h1>Restaurant</h1>
        </div>
      </div>
    </>
  );
}
