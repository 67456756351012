// src/config/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyCSVmPE4GZrhQaiYqRS3Q8N0numzbVgmOI",
  authDomain: "hotelrainbowsite.firebaseapp.com",
  projectId: "hotelrainbowsite",
  storageBucket: "hotelrainbowsite.appspot.com",
  messagingSenderId: "1034885134209",
  appId: "1:1034885134209:web:b7981fe0777ee81be59a80"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and export it
const db = getFirestore(app);

export default db;
