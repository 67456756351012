import React, { useEffect, useState } from 'react';
import { collection, doc, getDoc } from 'firebase/firestore';
import db from "../firebaseConfig"; // Make sure this path matches where you set up Firebase

export default function Gallery() {
  const [galleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const docRef = doc(db, 'Info', 'Images'); // Adjust this to match your Firebase structure
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const galleryData = docSnap.data().Gallery; // 'Gallery' is the field that contains the array of image URLs
          setGalleryImages(galleryData);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching gallery images:', error);
      }
    };

    fetchGalleryImages();
  }, []);

  return (
    <div className='flex flex-col items-center justify-center'>
      <h1
        className='text-teal-500 font-bold text-[4vh] tracking-widest'
      >
        OUR GALLERY
      </h1>
      <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-[100vw] px-[10vw] py-[10vh] gap-3 content-center'>
        {galleryImages.length > 0 ? (
          galleryImages.map((image, index) => (
            <div
              key={index}
              className='h-[40vh] w-auto rounded-md relative overflow-hidden'
            >
              <div
                className='bg-cover bg-center h-[40vh] w-full rounded-md absolute top-0 hover:scale-[1.02] transition-all ease-in-out'
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}
