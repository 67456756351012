import React, { useState } from "react";
import { Button, Alert } from "react-bootstrap";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import db from "../firebaseConfig"; // Adjust the path as necessary
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Login.css";

function Login() {
  const [isRegister, setIsRegister] = useState(false);
  const [form, setForm] = useState({
    email: "",
    name: "",
    phone: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate
  const auth = getAuth();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    try {
      // Create a new user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        form.email,
        form.password
      );
      const user = userCredential.user;

      // Save the user data to Firestore with phone number as the document ID
      await setDoc(doc(db, "Registration", user.uid), {
        email: form.email,
        name: form.name,
        phone: form.phone,
      });

      setSuccessMessage("Registration successful!");
      setForm({ email: "", name: "", phone: "", password: "" });
    } catch (error) {
      setError("Registration failed: " + error.message);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    try {
      // Sign in user with email and password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        form.email,
        form.password
      );
      const user = userCredential.user;

      // Save UID and login status to local storage
      localStorage.setItem("uid", user.uid);
      localStorage.setItem("isLoggedIn", "true");

      setSuccessMessage("Login successful!");
      setForm({ email: "", password: "" });
      navigate("/");
      window.location.reload();
    } catch (error) {
      setError("Login failed: " + error.message);
    }
  };
  return (
    <div className="login-container bg-sideview">
      <div className="logincard">
        <h2 className="loginh1">{isRegister ? "REGISTER" : "LOGIN"}</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        <form
          className="logitems"
          onSubmit={isRegister ? handleRegister : handleLogin}
        >
          {isRegister && (
            <div>
              <label className="loginlabel">NAME</label>
              <br />

              <input
                type="text"
                name="name"
                placeholder="ENTER YOUR NAME"
                value={form.name}
                onChange={handleInputChange}
                required
              />
              <br />

              <label className="loginlabel">PHONE</label>
              <br />

              <input
                type="text"
                name="phone"
                placeholder="ENTER YOUR PHONE NUMBER"
                value={form.phone}
                onChange={handleInputChange}
                required
              />
              <br />
            </div>
          )}

          <label className="loginlabel">EMAIL</label>

          <input
            type="email"
            name="email"
            placeholder="ENTER YOUR EMAIL"
            value={form.email}
            onChange={handleInputChange}
            required
          />

          <label className="loginlabel">PASSWORD</label>

          <input
            type="password"
            name="password"
            placeholder="ENTER YOUR PASSWORD"
            value={form.password}
            onChange={handleInputChange}
            required
          />
          <br />

          <button className="logbtn" type="submit">
            {isRegister ? "REGISTER" : "LOGIN"}
          </button>
        </form>
        <Button
        variant="link text-white"
          className="mb-5 mt-2 w-100"
          onClick={() => setIsRegister(!isRegister)}
        >
          {isRegister
            ? "Already have an account? Login"
            : "Don't have an account? Register"}
        </Button>
      </div>
    </div>
  );
}

export default Login;
