import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebaseConfig"; // Adjust this path based on your setup
import "./Footer.css"; // Import custom CSS for additional styling if needed

const Footer = () => {
  const [contactInfo, setContactInfo] = useState({
    Phone: "",
    Email: "",
    Address: "",
  });

  const [showModal, setShowModal] = useState(false); // State to control modal
  const [email, setEmail] = useState(""); // State to hold email input
  const [error, setError] = useState(""); // State to hold error message

  // Fetch contact information from Firestore
  useEffect(() => {
    const fetchContactInfo = async () => {
      const docRef = doc(db, "Info", "Contact");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setContactInfo(docSnap.data());
      }
    };
    fetchContactInfo();
  }, []);

  // Function to handle form submission and show the modal
  const handleSubscribe = (e) => {
    e.preventDefault();
    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic regex for email validation
    if (!emailPattern.test(email)) {
      setError("Please enter a valid email address."); // Set error message if invalid
      return;
    }

    setError(""); // Clear any existing error messages
    setShowModal(true); // Show the modal when the form is submitted
  };

  // Function to hide the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setEmail(""); // Clear email input when closing modal
  };

  return (
    <>
      <footer className="footer mt-auto py-4 text-white">
        <Container>
          <Row className="d-flex align-items-center justify-content-evenly">
            {/* Logo and Contact Information */}
            <Col md={4} className="mb-4">
              <h5 className="footer-logo mb-3">Rainbow</h5>
              <p className="mb-1">{contactInfo.Phone}</p>
              <p className="mb-1">{contactInfo.Email}</p>
              <p>{contactInfo.Address}</p>
            </Col>
            {/* Links Section */}
            <Col md={2} className="mb-4">
              <h5 className="mb-3">Links</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="/" className="text-white">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/About" className="text-white">
                    About Us
                  </a>
                </li>
                <li>
                  <a href="/Rooms" className="text-white">
                    Our Room
                  </a>
                </li>
                <li>
                  <a href="/Contact" className="text-white">
                    Contact Us
                  </a>
                </li>
              </ul>
            </Col>

            {/* Newsletter Subscription */}
            <Col md={3} className="mb-4">
              <h5 className="mb-3">Subscribe Newsletter</h5>
              <p>
                Subscribe to our newsletter to get notifications about new updates.
              </p>
              <Form onSubmit={handleSubscribe}>
                <div className="d-flex">
                  <Form.Control
                    type="email"
                    placeholder="Enter your email..."
                    className="me-2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Update email state on change
                  />
                  <Button variant="outline-light" type="submit">
                    <i className="fa fa-paper-plane"></i>
                  </Button>
                </div>
                {error && <p className="text-danger">{error}</p>} {/* Display error message */}
              </Form>
            </Col>
          </Row>
          <Row className="pt-4 border-top">
            <Col md={8}>
              <p className="mb-0">
                &copy; 2024 All rights reserved Hotel Rainbow
              </p>
            </Col>
          </Row>
        </Container>
      </footer>

      {/* Modal for showing subscription message */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Thank you for subscribing to our newsletter!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Footer;
