import React, { useState, useEffect } from 'react';
import RoomCard from './RoomCard'; // Ensure the path is correct
import db from '../firebaseConfig'; // Adjust path if necessary
import { collection, getDocs } from 'firebase/firestore';
import { Col } from 'react-bootstrap';
import { getSpaceUntilMaxLength } from '@testing-library/user-event/dist/utils';

function Rooms() {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    // Fetch room details from Firestore
    const fetchRooms = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Rooms'));
        const roomsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRooms(roomsData);
      } catch (error) {
        console.error("Error fetching room data: ", error);
      }
    };

    fetchRooms();
  }, []);


  const calculateAverageRating = (ratings) => {
    if (!ratings || ratings.length === 0) {
      return "No Rating Available";
    }
    const sum = ratings.reduce((a, b) => a + b, 0);
    const average = sum / ratings.length;
    return average.toFixed(1); // Rounded to 1 decimal point
  };

  return (
    <>
    <div className='bg-about h-[30vh] content-center text-center font-bold bg-center bg-fixed text-white text-[5vh] flex items-center justify-center'>
    Our Rooms
    </div>
    <div className="flex flex-col w-full">
        {rooms.map((room) => (
          <Col key={room.id} md={getSpaceUntilMaxLength} >
            <RoomCard
              roomid={room.id}
              ratings={calculateAverageRating(room.Rating) || "No Ratings Available"}
              image={room.image}
              name={room.name}
              price={room.price}
              offeredPrice={room.offeredPrice}
              size={room.size}
              capacity={room.capacity}
              services={room.services}
              beds={room.beds}
              nrooms={room.nrooms || "0"}
            />
          </Col>
        ))}
      </div>
    </>
  );
}

export default Rooms;
