import {React , useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AboutCard from "./AboutCard";
import ContactCard from "./ContactCard";
import Gallery from "./Gallery";

function Home() {
  return (
    <div className="min-h-screen overflow-hidden">
      <div className="bg-about h-[39vh] w-full bg-cover bg-fixed bg-center saturate-120 text-white content-center flex items-center justify-center text-center font-bold md:text-[5vh] text-[3vh] tracking-widest">
       <h1 className="text-center font-bold md:text-[5vh] text-[3vh] tracking-widest"> WELCOME TO
        <br />
        HOTEL RAINBOW</h1>
      </div>
      <AboutCard/>
      <Gallery/>
      <ContactCard/>
    </div>
  );
}

export default Home;
