import React, { useEffect, useState } from 'react';
import db from '../firebaseConfig'; // Adjust the path based on your setup
import { collection, getDocs, doc, getDoc, updateDoc, deleteDoc  } from 'firebase/firestore';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import jsPDF from 'jspdf'; // Import jsPDF
import 'jspdf-autotable';

export default function BookingCheck() {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showRoomModal, setShowRoomModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusAction, setStatusAction] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
    phone: '',
    status: 'All',
    isVerified: 'All',
    checkInDate: '',
    checkOutDate: '',
  });

  useEffect(() => {
    const fetchBookings = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'Bookings'));
      const bookingsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBookings(bookingsData);
      setLoading(false);
    };

    fetchBookings();
  }, []);

  const handleRoomDetails = async (roomId) => {
    const roomRef = doc(db, 'Rooms', roomId);
    const roomSnap = await getDoc(roomRef);
    if (roomSnap.exists()) {
      setSelectedRoom(roomSnap.data());
    } else {
      console.error('Room not found');
    }
    setShowRoomModal(true);
  };

  const handleCloseRoomModal = () => {
    setShowRoomModal(false);
    setSelectedRoom(null);
  };

  const handleVerify = (booking) => {
    setSelectedBooking(booking);
    setShowVerifyModal(true);
  };


  const handleCancelBooking = async () => {
    if (selectedBooking) {
      const bookingRef = doc(db, 'Bookings', selectedBooking.id);
      const roomRef = doc(db, 'Rooms', selectedBooking.roomid);

      // Delete the booking from the Bookings collection
      await deleteDoc(bookingRef);

      // Update the room capacity and bookedroomno in the Rooms collection
      const roomSnap = await getDoc(roomRef);
      if (roomSnap.exists()) {
        const roomData = roomSnap.data();
        const updatedCapacity = roomData.nrooms + 1;
        const updatedBookedRoomNo = roomData.bookedroomno.filter(
          (roomNo) => roomNo !== selectedBooking.roomNo
        );

        await updateDoc(roomRef, {
          nrooms: updatedCapacity,
          bookedroomno: updatedBookedRoomNo,
        });
      }

      // Update the bookings state after deletion
      setBookings((prevBookings) =>
        prevBookings.filter((b) => b.id !== selectedBooking.id)
      );

      // Close the cancel modal
      setShowCancelModal(false);
      setSelectedBooking(null);
    }
  };


  const handleConfirmVerify = async () => {
    if (selectedBooking) {
      const bookingRef = doc(db, 'Bookings', selectedBooking.id);
      await updateDoc(bookingRef, { isverified: true });

      setBookings((prevBookings) =>
        prevBookings.map((booking) =>
          booking.id === selectedBooking.id ? { ...booking, isverified: true } : booking
        )
      );

      setShowVerifyModal(false);
      setSelectedBooking(null);
    }
  };

  const handleCloseVerifyModal = () => {
    setShowVerifyModal(false);
    setSelectedBooking(null);
  };

  const handleStatusAction = (booking, action) => {
    setSelectedBooking(booking);
    setStatusAction(action);
    setShowStatusModal(true);
  };

    
  const handleConfirmStatusChange = async () => {
    if (selectedBooking) {
      const bookingRef = doc(db, 'Bookings', selectedBooking.id);
      const newStatus = statusAction === 'check-in' ? 'Checked In' : 'Checked Out';
  
      // Update the booking status
      await updateDoc(bookingRef, { Status: newStatus });
  
      // If checking out, update the room's nrooms
      if (statusAction === 'check-out') {
        const roomRef = doc(db, 'Rooms', selectedBooking.roomid); // Assuming roomid is in selectedBooking
        const roomSnap = await getDoc(roomRef);
        
        if (roomSnap.exists()) {
          const roomData = roomSnap.data();
          const updatedNrooms = roomData.nrooms + 1; // Increment nrooms by 1
          const updatedBookedRoomNo = roomData.bookedroomno.filter(roomNo => roomNo !== selectedBooking.roomNo); // Adjust roomNo if necessary
          
          // Update the Rooms document to increase nrooms and remove the room number from bookedroomno
          await updateDoc(roomRef, { nrooms: updatedNrooms, bookedroomno: updatedBookedRoomNo });
        }
      }
  
      // Update the bookings state
      setBookings((prevBookings) =>
        prevBookings.map((booking) =>
          booking.id === selectedBooking.id ? { ...booking, Status: newStatus } : booking
        )
      );
  
      setShowStatusModal(false);
      setSelectedBooking(null);
      setStatusAction(null);
    }
  };
  
  
  

  const handleCloseStatusModal = () => {
    setShowStatusModal(false);
    setSelectedBooking(null);
    setStatusAction(null);
  };

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  // Filter bookings based on the criteria
  const filteredBookings = bookings.filter((booking) => {
    const matchesName = booking.fullName.toLowerCase().includes(filters.name.toLowerCase());
    const matchesPhone = booking.phoneNumber.includes(filters.phone);
    const matchesStatus = filters.status === 'All' || booking.Status === filters.status;
    const matchesVerified = filters.isVerified === 'All' || (filters.isVerified === 'true' ? booking.isverified : !booking.isverified);
    const matchesCheckInDate = filters.checkInDate ? new Date(booking.checkInDate).toLocaleDateString('en-GB') === new Date(filters.checkInDate).toLocaleDateString('en-GB') : true;
    const matchesCheckOutDate = filters.checkOutDate ? new Date(booking.checkOutDate).toLocaleDateString('en-GB') === new Date(filters.checkOutDate).toLocaleDateString('en-GB') : true;

    return matchesName && matchesPhone && matchesStatus && matchesVerified && matchesCheckInDate && matchesCheckOutDate;
  });

  // Function to generate PDF bill


  function generateBill(booking) {
    const doc = new jsPDF();
  
    // Add Hotel Name and Address
    doc.setFontSize(16);
    doc.text('HOTEL RAINBOW', 105, 20, { align: 'center' });
    doc.setFontSize(12);
    doc.text('C.K Das Road Near Kmrupia Namghar Tezpur, Sunitpur, Assam 784001', 105, 28, { align: 'center' });
  
    // Add Hotel Contact Details
    doc.setFontSize(10);
    doc.text('Phone: +91 88224-20418', 160, 20, { align: 'left' });
  
    // Add Bill Number and Date
    doc.setFontSize(10);
    doc.text(`Bill No: ${booking.id}`, 15, 45);
    doc.text(`GST NO: 18ADRPN6741R3ZD`, 15, 55); // Adjusted position for better spacing
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 160, 45, { align: 'left' });
  
    // Add Guest Name and Other Information
    doc.text(`Name: ${booking.fullName}`, 15, 65);
    doc.text(`Room No: ${booking.roomNo}`, 160, 65,{ align: 'left' });
    doc.text(`Date of Arrival: ${new Date(booking.checkInDate).toLocaleDateString()}`, 15, 75);
    doc.text(`Date of Departure: ${new Date(booking.checkOutDate).toLocaleDateString()}`, 15, 85);
  
    // Create the Table Layout
    const tableData = [
        [booking.roomName, booking.NoOfDays, `RS ${booking.roomPrice}`, `RS ${booking.total}`],
        [`Extra Person `, `(${booking.extraPerson} x ${booking.NoOfDays})`, 'RS 450', `RS ${booking.extraPersonCost}`],
        ['CGST (6%)', '', '', `RS ${(parseFloat(booking.calculatedprice) * 0.06).toFixed(2)}`],
        ['SGST (6%)', '', '', `RS ${(parseFloat(booking.calculatedprice) * 0.06).toFixed(2)}`],
        // Add other room details or extras if needed
    ];
  
    // Add Table Headers and Body
    doc.autoTable({
      startY: 90, // Adjusted start position for the table
      head: [['Particular', 'Days/No', 'Rate', 'Amount']],
      body: tableData,
      theme: 'grid',
      styles: { fontSize: 10, halign: 'center', cellPadding: 4 }, // Added padding for better spacing
      headStyles: { fillColor: [120, 120, 120] },
    });
  
    // Calculate Total Amount
    const totalAmount = Number(booking.total) + Number(booking.extraPersonCost) + (parseFloat(booking.calculatedprice) * 0.12);
    
    // Add Total Amount
    doc.setFontSize(12);
    doc.text(`Total: RS ${totalAmount.toFixed(2)}`, 140, doc.autoTable.previous.finalY + 20, { align: 'left' });
  
    // Add Footer
    doc.text('E & O.E', 15, 280);
    doc.text('Manager', 150, 280);
  
    // Save the PDF with the booking ID as filename
    doc.save(`Bill_${booking.id}.pdf`);
  }
  
  const handleOpenCancelModal = (booking) => {
    setSelectedBooking(booking);
    setShowCancelModal(true);
  };


  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
    setSelectedBooking(null);
  };

  return (
    <div className="mt-5">
      <h1>Booking Details</h1>
      
      {/* Filter Inputs */}
      <div className="mb-3">
        <Form className='flex'>
          <Form.Group className="mr-2">
            Name
            <Form.Control
              type="text"
              placeholder="Name"
              name="name"
              value={filters.name}
              onChange={handleFilterChange}
            />
          </Form.Group>
          <Form.Group className="mr-2">
            Phone No
            <Form.Control
              type="text"
              placeholder="Phone No"
              name="phone"
              value={filters.phone}
              onChange={handleFilterChange}
            />
          </Form.Group>
          <Form.Group className="mr-2">
            Status
            <Form.Control as="select" name="status" value={filters.status} onChange={handleFilterChange}>
              <option value="All">All Status</option>
              <option value="Checked In">Checked In</option>
              <option value="Checked Out">Checked Out</option>
              <option value="None">New</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mr-2">
          Verification
            <Form.Control as="select" name="isVerified" value={filters.isVerified} onChange={handleFilterChange}>
              <option value="All">All Verified Status</option>
              <option value="true">Verified</option>
              <option value="false">Not Verified</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mr-2">
            Checked In Date
            <Form.Control
              type="date"
              name="checkInDate"
              value={filters.checkInDate}
              onChange={handleFilterChange}
            />
          </Form.Group>
          <Form.Group className="mr-2">
            Checked Out Date
            <Form.Control
              type="date"
              name="checkOutDate"
              value={filters.checkOutDate}
              onChange={handleFilterChange}
            />
          </Form.Group>
        </Form>
      </div>

      {loading ? (
        <p>Loading bookings...</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>User ID</th>
              <th>Book ID/Time</th>
              <th>Room Details</th>
              <th>Check-In Date</th>
              <th>Check-Out Date</th>
              <th>Status</th>
              <th>ID Proof</th>
              <th>Verified</th>
              <th>Persons</th>
              <th>Total</th>
              <th>Actions</th>
              <th>Bill</th>
              <th>Option</th>
            </tr>
          </thead>
          <tbody>
            {filteredBookings.map((booking, index) => (
              <tr key={booking.id}>
                <td>{index + 1}</td>
                <td>{booking.fullName}</td>
                <td>{booking.phoneNumber}</td>
                <td>{booking.userid}</td>
                <td>{booking.id}</td>
                <td>
                  <Button variant="info" onClick={() => handleRoomDetails(booking.roomid)}>
                    View
                  </Button>
                </td>
                <td>{new Date(booking.checkInDate).toLocaleDateString('en-GB')}</td>
                <td>{new Date(booking.checkOutDate).toLocaleDateString('en-GB')}</td>
                <td>{booking.Status}</td>
                <td>
                  <a href={booking.idProofUrl} target="_blank" rel="noopener noreferrer">
                    View ID
                  </a>
                </td>
                <td>{booking.isverified ? 'Yes' : 'No'}</td>
                <td>{booking.totalPersons}</td>
                <td>RS  { Number(booking.calculatedprice) + parseFloat(Number(booking.calculatedprice)) * 0.06}</td>
                <td>
                  {booking.isverified ? (
                    <>
                      {booking.Status === 'None' ? (
                        <Button variant="success" onClick={() => handleStatusAction(booking, 'check-in')}>
                          Check In
                        </Button>
                      ) : booking.Status === 'Checked In' ? (
                        <Button variant="danger" onClick={() => handleStatusAction(booking, 'check-out')}>
                          Check Out
                        </Button>
                      ) : null}
                    </>
                  ) : (
                    <Button variant="warning" onClick={() => handleVerify(booking)}>
                      Verify
                    </Button>
                  )}
                </td>
                <td>
                  <Button variant="primary" onClick={() => generateBill(booking)}>
                    Generate Bill
                  </Button>
                </td>
                <td>
                {booking.Status ==="None" ? (
                  <Button
                  variant="danger"
                  onClick={() => handleOpenCancelModal(booking)}
                >
                  Cancel
                </Button>
                ): (
                  <Button
                  variant="dark"
                >
                  Cancel
                </Button>
                )}
              </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal for room details */}
      <Modal show={showRoomModal} onHide={handleCloseRoomModal}>
        <Modal.Header closeButton>
          <Modal.Title>Room Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRoom ? (
            <div>
              <p><strong>Name:</strong> {selectedRoom.name}</p>
              <p><strong>Price:</strong>RS {selectedRoom.price}</p>
              <p><strong>Offered Price:</strong> RS {selectedRoom.offeredPrice}</p>
              <p><strong>Size:</strong> {selectedRoom.size} sq ft</p>
              <p><strong>Capacity:</strong> {selectedRoom.capacity} persons</p>
              <p><strong>Beds:</strong> {selectedRoom.beds}</p>
              <p><strong>Available Rooms:</strong> {selectedRoom.nrooms}</p>
              <p><strong>Services:</strong> {selectedRoom.services.join(', ')}</p>
              <p>
                <strong>Image:</strong> <br />
                <img src={selectedRoom.image} alt={selectedRoom.name} style={{ maxWidth: '100%' }} />
              </p>
            </div>
          ) : (
            <p>Loading room details...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRoomModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for verification */}
      <Modal show={showVerifyModal} onHide={handleCloseVerifyModal}>
        <Modal.Header closeButton>
          <Modal.Title>Verify ID Proof</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedBooking && (
            <div>
              <p><strong>ID Proof:</strong></p>
              <img
                src={selectedBooking.idProofUrl}
                alt="ID Proof"
                style={{ maxWidth: '100%' }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseVerifyModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmVerify}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for status change confirmation */}
      <Modal show={showStatusModal} onHide={handleCloseStatusModal}>
        <Modal.Header closeButton>
          <Modal.Title>{statusAction === 'check-in' ? 'Check In Confirmation' : 'Check Out Confirmation'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Are you sure you want to ${statusAction === 'check-in' ? 'check in' : 'check out'}?`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseStatusModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmStatusChange}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cancel Confirmation Modal */}
      <Modal show={showCancelModal} onHide={handleCloseCancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Booking Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this booking?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCancelModal}>
            No
          </Button>
          <Button variant="danger" onClick={handleCancelBooking}>
            Yes, Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      
    </div>
  );
}
