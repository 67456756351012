import React from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Rooms from './components/Rooms';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Login from './components/Login';
import MyRooms from './components/MyRooms';
import AdminHome from './components/AdminHome';
import RoomDetails from './components/RoomDetails';

const App = () => {
  const location = useLocation();

  return (
    <div className="parent">
      {/* Conditionally render Navbar if not on the /admin route */}
      {location.pathname !== '/admin' && <Navbar />}

      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/room/:roomid" element={<RoomDetails />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/myrooms" element={<MyRooms />} />
          <Route path="/admin" element={<AdminHome />} />
        </Routes>
      </div>

      {/* Conditionally render Footer if not on the /admin route */}
      {location.pathname !== '/admin' && <Footer />}
    </div>
  );
};

export default App;
