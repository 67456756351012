import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { doc, getDoc } from "firebase/firestore";
import emailjs from "emailjs-com";
import db from "../firebaseConfig"; // Adjust based on your setup

export default function Contact() {
  const [contactInfo, setContactInfo] = useState({
    Phone: "",
    Email: "",
    Address: "",
    Facebook: "",
    Instagram: "",
    Whatsapp: "",
  });
  const [phone, setPhone] = useState("");

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });

    const fetchContactInfo = async () => {
      const docRef = doc(db, "Info", "Contact");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setContactInfo(docSnap.data());
      }
    };

    fetchContactInfo();
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    emailjs
      .sendForm(
        "service_970v2uq", // EmailJS service ID
        "template_of8xp2p", // EmailJS template ID
        e.target, // form
        "6mjqdxjWQ5tzr3Wdk" // Your user ID from EmailJS
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.log("Email sending error:", error.text);
          alert("Failed to send message. Try again later.");
        }
      );

    // Reset form after submission
    e.target.reset();
  };
  const handlePhoneChange = (e) => {
    const value = e.target.value;
  
    // Only allow digits and limit to 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setPhone(value);
    }
  };

  return (
    <div className="min-h-[100vh] overflow-hidden">
      <div className="h-[40vh] bg-about bg-cover bg-center w-full bg-fixed text-center flex flex-col justify-center items-center font-bold font-Oswald text-[5vh] text-white">
        CONTACT US
        <div className="flex justify-center items-center text-[3vh] gap-[5vw] lg:gap-[2vw] text-neutral-900 pt-[2vh]">
          <a
            href={contactInfo.Facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-facebook bg-teal-500 border-teal-500 text-white border-2 p-4 rounded-full transition-all ease-in-out hover:bg-neutral-900 hover:scale-125"></i>
          </a>
          <a
            href={contactInfo.Instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-instagram bg-teal-500 border-teal-500 text-white border-2 p-4 rounded-full transition-all ease-in-out hover:bg-neutral-900 hover:scale-125"></i>
          </a>
          <a
            href={`https://wa.me/${contactInfo.Whatsapp}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-whatsapp bg-teal-500 border-teal-500 text-white border-2 p-4 rounded-full transition-all ease-in-out hover:bg-neutral-900 hover:scale-125"></i>
          </a>
        </div>
      </div>

      {/* Contact form card */}
      <div className="w-full h-[200vh] lg:h-[80vh] flex flex-col lg:flex-row items-center justify-evenly px-[5vw] lg:py-[5vh]">
        <div className="h-[100vh] lg:h-[80vh] lg:w-[48%] w-full flex flex-col text-center justify-evenly items-center">
          <h3
            className="text-[2vh] text-teal-500 font-bold font-Oswald lg:text-start lg:w-[40vw]"
            data-aos="fade-up"
          >
            CONTACT WITH US
          </h3>
          <h3
            className="text-neutral-900 text-[4vh] font-bold font-Oswald lg:text-start lg:w-[40vw]"
            data-aos="fade-up"
          >
            LET'S TALK NOW
          </h3>
          <p
            className="text-neutral-900 text-[1.5vh] lg:w-[40vw] lg:text-start"
            data-aos="fade-up"
          >
            At Hotel Rainbow, we are dedicated to providing exceptional service and ensuring that every guest enjoys a comfortable and memorable stay. 
          </p>
          <div className="flex flex-col text-neutral-900 lg:w-[40vw] lg:text-start py-[2vh] text-[2vh] gap-2">
            <div>
              <i className="fa-solid fa-phone text-teal-500 pe-[1vw]"></i>{" "}
              {contactInfo.Phone}
            </div>
            <div>
              <i className="fa-solid fa-envelope  text-teal-500 pe-[1vw]"></i>{" "}
              {contactInfo.Email}
            </div>
            <div>
              <i className="fa-solid fa-location-dot  text-teal-500 pe-[1vw]"></i>{" "}
              {contactInfo.Address}
            </div>
          </div>
          <div data-aos="fade-right">
            <iframe
              className="w-[80vw] lg:w-[40vw] h-[60vh] lg:h-[30vh] rounded-sm"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114536.15250625926!2d91.95130946840821!3d26.220910338700964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3744e9850617f617%3A0xa2dcd8228d3b844e!2sHotel%20Rainbow!5e0!3m2!1sen!2sin!4v1729520958689!5m2!1sen!2sin"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        {/* Form */}
        <form
          onSubmit={sendEmail}
          className="h-[80vh] lg:h-[73.5vh] lg:w-[40%] w-full border-neutral-200 border-1 rounded-lg   shadow-sm flex flex-col justify-evenly items-center px-[10vw] lg:px-[5vw]"
          data-aos="fade-left"
        >
          <h3 className="text-neutral-900 font-Oswald font-bold text-[3vh]">
            GET IN TOUCH
          </h3>
          <input
            className="outline-1 outline-teal-500 text-neutral-900 outline-none w-full bg-transparent py-[1vh] caret-teal-500"
            placeholder="NAME"
            name="name"
            type="text"
            required
          />
          <input
            className="outline-1 outline-teal-500 text-neutral-900 outline-none w-full bg-transparent py-[1vh] caret-teal-500"
            placeholder="EMAIL"
            name="email"
            type="email"
            required
          />
          <input
  className="outline-1 outline-teal-500 text-neutral-900 outline-none w-full bg-transparent py-[1vh] caret-teal-500"
  placeholder="PHONE"
  name="phone"
  type="tel"
  value={phone} // Controlled input
  onChange={handlePhoneChange} // Handle change to validate input
  required
/>
          <input
            className="outline-1 outline-teal-500 text-neutral-900 outline-none w-full bg-transparent py-[1vh] caret-teal-500"
            placeholder="SUBJECT"
            name="subject"
            type="text"
            required
          />
          <input
            className="outline-1 outline-teal-500 text-neutral-900 outline-none w-full bg-transparent py-[1vh] caret-teal-500"
            placeholder="MESSAGE"
            name="message"
            type="text"
            required
          />
          <button className="bg-teal-500 p-[1vh] w-full rounded-md border-teal-500 border-2 hover:bg-transparent text-neutral-700 font-bold font-Oswald tracking-wider text-[2vh]">
            SEND MESSAGE
          </button>
        </form>
      </div>
    </div>
  );
}
