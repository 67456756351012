import React, { useState, useEffect } from "react";
import db from "../firebaseConfig"; // Adjust the path based on your setup
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import RoomCard from "../components/RoomCard";
import { Form, Button, Modal } from "react-bootstrap";
import CircularProgress from "@mui/material/CircularProgress"; // Import the CircularProgress component

function EditRooms() {
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [form, setForm] = useState({
    id: null,
    imageFiles: [],
    imageUrls: [],
    name: "",
    price: "",
    offeredPrice: "",
    size: "",
    capacity: "",
    services: "",
    beds: "",
    nrooms: "",
    totalnoofrooms: "", // New field for total number of rooms
    roomno: [], // New field for array of room numbers
  });
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [roomToDelete, setRoomToDelete] = useState(null);

  useEffect(() => {
    const fetchRooms = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "Rooms"));
      const roomsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRooms(roomsData);
      setLoading(false);
    };

    fetchRooms();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleFileChange = (e, index) => {
    const newImageFiles = [...form.imageFiles];
    newImageFiles[index] = e.target.files[0];
    setForm({ ...form, imageFiles: newImageFiles });
  };

  const handleAddImage = () => {
    setForm({ ...form, imageFiles: [...form.imageFiles, null] });
  };

  const handleRemoveImage = (index) => {
    const newImageFiles = [...form.imageFiles];
    newImageFiles.splice(index, 1);
    setForm({ ...form, imageFiles: newImageFiles });
  };

  const handleRemoveImageUrl = (index) => {
    const newImageUrls = [...form.imageUrls];
    newImageUrls.splice(index, 1);
    setForm({ ...form, imageUrls: newImageUrls });
  };

  // Add room number input handling
  const handleRoomNumberChange = (index, value) => {
    const newRoomNumbers = [...form.roomno];
    newRoomNumbers[index] = value;
    setForm({ ...form, roomno: newRoomNumbers });
  };

  const handleAddRoomNumber = () => {
    if (form.roomno.length < form.totalnoofrooms) {
      setForm({ ...form, roomno: [...form.roomno, ""] });
    } else {
      alert(`Maximum number of room numbers (${form.totalnoofrooms}) reached.`);
    }
  };

  const handleRemoveRoomNumber = (index) => {
    const newRoomNumbers = [...form.roomno];
    newRoomNumbers.splice(index, 1);
    setForm({ ...form, roomno: newRoomNumbers });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);

    if (form.imageFiles.length === 0 && form.imageUrls.length === 0) {
      alert("Please upload at least one image.");
      setFormLoading(false);
      return;
    }

    let imageUrls = [...form.imageUrls];

    for (const file of form.imageFiles) {
      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `room-images/${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        imageUrls.push(url);
      }
    }

    // Prepare room data to update or add
    const roomData = {
      image: imageUrls,
      name: form.name,
      price: Number(form.price),
      offeredPrice: Number(form.offeredPrice),
      size: Number(form.size),
      beds: form.beds,
      capacity: Number(form.capacity),
      services: form.services.split(",").map((service) => service.trim()),
      nrooms: Number(form.nrooms),
      roomno: form.roomno, // Room numbers
      totalnoofrooms: Number(form.totalnoofrooms), // Total number of rooms
    };
    

    if (form.id) {
      const roomRef = doc(db, "Rooms", form.id);
      await updateDoc(roomRef, roomData);
    } else {
      await addDoc(collection(db, "Rooms"), roomData);
    }

    // Reset the form
    setForm({
      id: null,
      imageFiles: [],
      imageUrls: [],
      name: "",
      price: "",
      offeredPrice: "",
      size: "",
      capacity: "",
      services: "",
      beds: "",
      nrooms: "",
      totalnoofrooms: "",
      roomno: [],
    });

    // Fetch updated rooms data
    const querySnapshot = await getDocs(collection(db, "Rooms"));
    const roomsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setRooms(roomsData);
    setShowModal(false);
    setFormLoading(false);
  };

  const confirmDeleteRoom = (roomId) => {
    setRoomToDelete(roomId);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (roomToDelete) {
      await deleteDoc(doc(db, "Rooms", roomToDelete));
      const querySnapshot = await getDocs(collection(db, "Rooms"));
      const roomsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRooms(roomsData);
      setRoomToDelete(null);
      setShowDeleteModal(false);
    }
  };

  const handleEdit = (room) => {
    setForm({
      id: room.id,
      imageFiles: [],
      imageUrls: room.image,
      name: room.name,
      price: room.price,
      offeredPrice: room.offeredPrice,
      size: room.size,
      capacity: room.capacity,
      services: room.services.join(", "),
      beds: room.beds,
      nrooms: room.nrooms,
      totalnoofrooms: room.totalnoofrooms || "", // Include total number of rooms
      roomno: room.roomno || [], // Load existing room numbers
    });
    setShowModal(true);
  };

  const handleShowModal = () => {
    setForm({
      id: null,
      imageFiles: [],
      imageUrls: [],
      name: "",
      price: "",
      offeredPrice: "",
      size: "",
      capacity: "",
      services: "",
      beds: "",
      nrooms: "",
      totalnoofrooms: "",
      roomno: [],
    });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setRoomToDelete(null);
  };

  const calculateAverageRating = (ratings) => {
    if (!ratings || ratings.length === 0) {
      return "No Rating Available";
    }
    const sum = ratings.reduce((a, b) => a + b, 0);
    const average = sum / ratings.length;
    return average.toFixed(1); // Rounded to 1 decimal point
  };

  return (
    <div className="min-h-screen relative">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <CircularProgress />
        </div>
      ) : (
        <div className="flex flex-col w-full">
          {rooms.map((room) => (
            <div key={room.id} className="mb-[10vh]">
              <RoomCard
              roomid={room.id}
                image={room.image}
                name={room.name}
                ratings={calculateAverageRating(room.Rating) || "No Ratings Available"}
                price={room.price}
                offeredPrice={room.offeredPrice}
                size={room.size}
                capacity={room.capacity}
                services={room.services}
                beds={room.beds}
                nrooms={room.nrooms}
              />
              <div className="flex justify-end items-center w-[96vw] gap-[1vw] mx-auto">
                <button
                  onClick={() => handleEdit(room)}
                  className="bg-yellow-400 w-[10%] lg:h-[4vh] h-[6vh] rounded-sm text-[2.5vh] hover:scale-105 transition-all text-neutral-900 font-bold"
                >
                  Edit
                </button>
                <button
                  onClick={() => confirmDeleteRoom(room.id)}
                  className="bg-red-500 w-[10%] lg:h-[4vh] h-[6vh] rounded-sm text-[2.5vh] hover:scale-105 transition-all text-white font-bold"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <button
        className="fixed bottom-[4vh] right-[4vh] bg-blue-600 text-white text-3xl rounded-full w-12 h-12 flex items-center justify-center hover:bg-blue-800 transition-colors"
        onClick={handleShowModal}
      >
        +
      </button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{form.id ? "Edit Room" : "Add New Room"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Uploaded Images</Form.Label>
              {form.imageUrls.map((url, index) => (
                <div key={index} className="mb-2">
                  <img src={url} alt={`Uploaded ${index}`} width="100" />
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveImageUrl(index)}
                    className="mt-1"
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Image Upload</Form.Label>
              {form.imageFiles.map((file, index) => (
                <div key={index} className="mb-2">
                  <Form.Control
                    type="file"
                    onChange={(e) => handleFileChange(e, index)}
                  />
                  {form.imageFiles.length > 1 && (
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveImage(index)}
                      className="mt-1"
                    >
                      Remove
                    </Button>
                  )}
                </div>
              ))}
              <Button onClick={handleAddImage} className="mt-2">
                + Add Image
              </Button>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Room Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={form.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                name="price"
                value={form.price}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Offered Price</Form.Label>
              <Form.Control
                type="number"
                name="offeredPrice"
                value={form.offeredPrice}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Size (in sqm)</Form.Label>
              <Form.Control
                type="number"
                name="size"
                value={form.size}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Beds</Form.Label>
              <Form.Control
                type="text"
                name="beds"
                value={form.beds}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Capacity (in people)</Form.Label>
              <Form.Control
                type="number"
                name="capacity"
                value={form.capacity}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Services (comma separated)</Form.Label>
              <Form.Control
                type="text"
                name="services"
                value={form.services}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Number of Rooms Available</Form.Label>
              <Form.Control
                type="number"
                name="nrooms"
                value={form.nrooms}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Total Number of Rooms</Form.Label>
              <Form.Control
                type="number"
                name="totalnoofrooms"
                value={form.totalnoofrooms}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Room Numbers</Form.Label>
              {form.roomno.map((roomNumber, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <Form.Control
                    type="text"
                    value={roomNumber}
                    onChange={(e) => handleRoomNumberChange(index, e.target.value)}
                    placeholder={`Room No. ${index + 1}`}
                  />
                  {form.roomno.length > 1 && (
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveRoomNumber(index)}
                      className="ml-2"
                    >
                      Remove
                    </Button>
                  )}
                </div>
              ))}
              <Button onClick={handleAddRoomNumber} className="mt-2">
                + Add Room Number
              </Button>
            </Form.Group>
            <Button type="submit" disabled={formLoading}>
              {formLoading ? "Saving..." : "Save"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this room?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditRooms;
