import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, getDoc, setDoc, arrayUnion } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import db from "../firebaseConfig";
import moment from "moment-timezone";

const RoomCard = ({
  image,
  name,
  ratings,
  price,
  offeredPrice,
  size,
  capacity,
  services,
  beds,
  nrooms,
  roomid,
}) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    numberOfPersons: 1,
    checkInDate: "",
    checkOutDate: "",
    extraPerson: 0,
    idProofFile: null,
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false); // New state for confirmation modal
  const [uploading, setUploading] = useState(false);
  const [roomNumbers, setRoomNumbers] = useState([]); // State to hold room numbers
  const [selectedRoomNo, setSelectedRoomNo] = useState(""); // State to hold the selected room number
  const navigate = useNavigate();
  const auth = getAuth();
  const storage = getStorage();

  useEffect(() => {
    // Fetch the room numbers from Firestore
    const fetchRoomNumbers = async () => {
      try {
        const roomDoc = await getDoc(doc(db, "Rooms", roomid));
        if (roomDoc.exists()) {
          const roomData = roomDoc.data();
          const bookedRoomNos = roomData.bookedroomno || [];
          const availableRoomNos = roomData.roomno.filter(
            (roomNo) => !bookedRoomNos.includes(roomNo)
          );
          setRoomNumbers(availableRoomNos);
        }
      } catch (error) {
        console.error("Error fetching room numbers:", error);
      }
    };

    fetchRoomNumbers();
  }, [roomid]);

  const calculateNoOfDays = () => {
    const checkIn = moment(formData.checkInDate);
    const checkOut = moment(formData.checkOutDate);
    return checkOut.diff(checkIn, "days");
  };

  const handleBookRoom = async () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const user = auth.currentUser;

    if (!isLoggedIn || !user) {
      setShowLoginModal(true);
      return;
    }

    try {
      const userDoc = await getDoc(doc(db, "Registration", user.uid));
      if (userDoc.exists()) {
        setShowSuccessModal(true);
      } else {
        setShowLoginModal(true);
      }
    } catch (error) {
      console.error("Error checking user data:", error);
    }
  };

  const handleLoginRedirect = () => {
    navigate("/login");
    setShowLoginModal(false);
  };

  const handleCloseModals = () => {
    setShowLoginModal(false);
    setShowSuccessModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      idProofFile: e.target.files[0],
    }));
  };

  const handleRoomNoChange = (e) => {
    setSelectedRoomNo(e.target.value); // Update selected room number
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    const today = moment().startOf("day");
    const checkInDate = moment(formData.checkInDate);
    const checkOutDate = moment(formData.checkOutDate);

    if (checkInDate.isBefore(today)) {
      alert("Check-in date cannot be in the past.");
      setUploading(false);
      return;
    }

    if (checkOutDate.isBefore(checkInDate)) {
      alert("Check-out date cannot be before check-in date.");
      setUploading(false);
      return;
    }

    try {
      let idProofUrl = "";
      if (formData.idProofFile) {
        const idProofRef = ref(storage, `idProofs/${auth.currentUser.uid}`);
        await uploadBytes(idProofRef, formData.idProofFile);
        idProofUrl = await getDownloadURL(idProofRef);
      }

      const bookingTime = moment()
        .tz("Asia/Kolkata")
        .format("YYYY-MM-DD-hh-mm-ss-A");
      const NoOfDays = calculateNoOfDays();

      // Create a new booking document in Firestore
      const bookingRef = doc(db, "Bookings", bookingTime);
      await setDoc(bookingRef, {
        roomid,
        roomPrice: offeredPrice,
        roomName: name,
        fullName: formData.fullName,
        phoneNumber: formData.phoneNumber,
        numberOfPersons: formData.numberOfPersons,
        checkInDate: formData.checkInDate,
        checkOutDate: formData.checkOutDate,
        NoOfDays,
        extraPerson: formData.extraPerson,
        idProofUrl,
        total: NoOfDays * offeredPrice,
        userid: localStorage.getItem("uid"),
        isverified: false,
        Status: "None",
        calculatedprice:
          formData.extraPerson * 450 * NoOfDays + NoOfDays * offeredPrice,
        extraPersonCost: formData.extraPerson * 450 * NoOfDays,
        totalPersons:
          parseInt(formData.extraPerson, 10) +
          parseInt(formData.numberOfPersons, 10),
        roomNo: selectedRoomNo,
      });

      // Update the room document to include the booked room number
      const roomRef = doc(db, "Rooms", roomid);
      await setDoc(
        roomRef,
        {
          bookedroomno: arrayUnion(selectedRoomNo),
          nrooms: nrooms - 1, // Decrease the number of available rooms
        },
        { merge: true }
      );

      // Update the user's document in Firestore to include the new booking ID
      const userRef = doc(db, "Registration", auth.currentUser.uid);
      await setDoc(
        userRef,
        {
          myrooms: arrayUnion(bookingRef.id),
        },
        { merge: true }
      );

      console.log("Booking form data submitted:", formData);
      setUploading(false);
      handleCloseModals();
      setShowConfirmModal(true); // Show the confirmation modal
    } catch (error) {
      console.error("Error submitting form:", error);
      setUploading(false);
    }
  };

  return (
    <div className="h-[75vh] md:h-[80vh] rounded-md m-[3vh] justify-between flex flex-col lg:flex-row">
      <div className="relative overflow-hidden h-[40vh] md:h-[50vh] w-full lg:w-[60vw] lg:h-[75vh] my-auto lg:rounded-md">
        <Carousel>
          {image.map((imgUrl, index) => (
            <Carousel.Item key={index}>
              <img
                src={imgUrl}
                alt={`Room image ${index + 1}`}
                className="lg:w-[60vw] lg:h-[75vh] md:h-[50vh] h-[35vh] w-full object-cover rounded-t-md lg:rounded-md"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      <div className="flex items-start justify-between ps-[2vw] lg:ps-0 mb-[1vh] lg:flex-col lg:w-[35vw] lg:py-[4vh]">
        <div className="flex flex-col items-start justify-evenly h-[30%] w-[46%] lg:w-[35vw] ">
          <h3 className="text-[3vh] font-bold text-teal-500 lg:text-[7vh]">
            {name}
          </h3>
          <div className="text-[1.5vh] lg:text-[2vh] font-mono flex py-[.5vh] ps-1 justify-start items-center w-full">
            {ratings} {ratings =="No Rating Available" ? ("") : (<div><i className="fa-solid fa-star text-[1.6vh] text-yellow-400 ms-1"></i> Rating <button className="bg-blue-400 text-white rounded-sm px-1 ms-[1vw]"
  onClick={() => navigate(`/room/${roomid}`)}>view</button></div>)}
          </div>
          <p className="text-[1.5vh] text-red-500 lg:text-[3vh]">
            *{nrooms} Rooms Available
          </p>
          <p className="gap-[1.5vw] flex">
            <span className="text-[2vh] lg:text-[4vh] line-through text-red-500 italic">
              ₹{price}
            </span>
            <span className="text-[2vh] lg:text-[4vh]">₹{offeredPrice}</span>
          </p>
        </div>

        <div className="flex items-start justify-between mt-auto w-[40%] lg:h-[40%] lg:w-full">
          <div className="w-[40%] ">
            <p className="text-[1.5vh] lg:text-[2.5vh] text-neutral-600">
              <strong>Size:</strong>
              <p>{size} sq ft</p>
            </p>
            <p className="text-[1.5vh]  lg:text-[2.5vh] text-neutral-600">
              <strong>Beds:</strong>
              <p>{beds}</p>
            </p>
          </div>
          <div className="w-[40%] ">
            <p className="text-[1.5vh] lg:text-[2.5vh] text-neutral-600">
              <strong>Capacity:</strong>
              <p>{capacity} persons</p>
            </p>
            <p className="text-[1.5vh] lg:text-[2.5vh] text-neutral-600">
              <strong>Services:</strong>
              <p>{services.join(", ")}</p>
            </p>
          </div>
        </div>
        {nrooms !== "0" ? (
          <button
            className="bg-teal-500 text-white w-[95%] rounded-md py-[2.5vh] text-[2.5vh] font-bold hidden mb-[2vw] lg:block mx-auto lg:w-[100%]"
            onClick={handleBookRoom}
          >
            Book Now
          </button>
        ) : (
          <button className="bg-red-500 text-white rounded-md py-[2.5vh] text-[2.5vh] font-bold w-[95%] hidden mb-[2vw] lg:block mx-auto lg:w-[90%]">
            Not Available
          </button>
        )}
      </div>
      {nrooms !== "0" ? (
        <button
          className="bg-teal-500 text-white w-[95%] rounded-md py-[2.5vh] text-[2.5vh] font-bold block mb-[2vw] lg:hidden mx-auto"
          onClick={handleBookRoom}
        >
          Book Now
        </button>
      ) : (
        <button className="bg-red-500 text-white rounded-md py-[2.5vh] text-[2.5vh] font-bold w-[95%] block mb-[2vw] lg:hidden mx-auto">
          Not Available
        </button>
      )}

      {/* Login Confirmation Modal */}
      <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please log in to proceed with booking.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleLoginRedirect}>
            Login
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={handleCloseModals}>
        <Modal.Header closeButton>
          <Modal.Title>Booking Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="fullName" className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="phoneNumber" className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="numberOfPersons" className="mb-3">
              <Form.Label>Number of Persons</Form.Label>
              <Form.Control
                as="select"
                name="numberOfPersons"
                value={formData.numberOfPersons}
                onChange={handleInputChange}
                required
              >
                {/* Generate options based on the capacity */}
                {[...Array(capacity).keys()].map((num) => (
                  <option key={num + 1} value={num + 1}>
                    {num + 1}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="checkInDate" className="mb-3">
              <Form.Label>Check-in Date</Form.Label>
              <Form.Control
                type="date"
                name="checkInDate"
                value={formData.checkInDate}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="checkOutDate" className="mb-3">
              <Form.Label>Check-out Date</Form.Label>
              <Form.Control
                type="date"
                name="checkOutDate"
                value={formData.checkOutDate}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="extraPerson" className="mb-3">
              <Form.Label>Extra Persons</Form.Label>
              <Form.Control
                as="select"
                name="extraPerson"
                value={formData.extraPerson || 0} // Default to 0 if formData.extraPerson is undefined or null
                onChange={handleInputChange}
              >
                <option value={0}>0</option> {/* Default option */}
                {[...Array(capacity).keys()].map((extra) => (
                  <option key={extra} value={extra + 1}>
                    {extra + 1}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="roomNo" className="mb-3">
              <Form.Label>Select Room Number</Form.Label>
              <Form.Control
                as="select"
                value={selectedRoomNo}
                onChange={handleRoomNoChange}
                required
              >
                <option value="" disabled>
                  Select a room number
                </option>
                {roomNumbers.map((roomNo, index) => (
                  <option key={index} value={roomNo}>
                    {roomNo}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="idProofFile" className="mb-3">
              <Form.Label>ID Proof</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} required />
            </Form.Group>

            <Button variant="primary" type="submit" disabled={uploading}>
              {uploading ? "Booking..." : "Submit"}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModals}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
<Modal 
  show={showConfirmModal} 
  onHide={() => setShowConfirmModal(false)} 
  backdrop="static"   // Prevent closing by clicking outside
  keyboard={false}     // Prevent closing with "Esc" key
>
  <Modal.Header closeButton>
    <Modal.Title>Booking Confirmed</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>Thanks for choosing Hotel Rainbow!</p>
    <p>For further assistance or payment, please contact us.</p>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

    </div>
  );
};

export default RoomCard;
