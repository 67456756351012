import React from 'react'

export default function ContactCard() {
  return (
    <div className='h-[30vh] lg:h-[20vh] w-[100vw] my-[10vh] flex items-center justify-center overflow-hidden'>
        <div className='bg-about h-full w-[80vw] lg:w-[60vw] bg-center bg-fixed flex justify-evenly lg:justify-between items-center text-white px-[3vw] relative rounded-sm flex-col lg:flex-row'>
            <div className=' z-10 flex flex-col lg:items-start'>
                <h1 className='text-[2.5vh] lg:text-[3.5vh] font-bold text-teal-500 lg:text-start text-center'>CONTACT US NOW <span className=' text-white'>!!</span></h1>
                <p className='text-[1.5vh] lg:text-start text-center' >At Hotel Rainbow, your satisfaction is our priority. Let us know how we can make your experience even better!</p>
            </div>
            <a href='/contact' className='z-10 decoration-transparent select-none hover:scale-105 transition-all'>
            <div className='text-[1.5vh] font-bold bg-teal-500 px-[2.5vw] py-[1.5vh] rounded-sm z-10 text-white'>
                CONTACT US
            </div>
            </a>
            <div className='absolute bg-[#000000a0] h-full w-[80vw] lg:w-[60vw] left-0 rounded-sm'>

            </div>
        </div>
    </div>
  )
}
