import React, { useState, useEffect } from "react";
import db from "../firebaseConfig"; // Adjust the path based on your setup
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Button, Form, Modal } from "react-bootstrap";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";

export default function EditImages() {
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [newImageFile, setNewImageFile] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [imageToRemove, setImageToRemove] = useState(null); // Track image to be deleted

  useEffect(() => {
    const fetchGallery = async () => {
      setLoading(true);
      const docRef = doc(db, "Info", "Images");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setGallery(docSnap.data().Gallery || []);
      }
      setLoading(false);
    };

    fetchGallery();
  }, []);

  const handleImageChange = (e, index) => {
    const newGallery = [...gallery];
    newGallery[index] = e.target.value; // Update specific image URL
    setGallery(newGallery);
  };

  const handleAddNewImage = async () => {
    if (!newImageFile) return;

    setFormLoading(true);
    const storage = getStorage();
    const storageRef = ref(storage, `gallery-images/${newImageFile.name}`);
    await uploadBytes(storageRef, newImageFile);
    const url = await getDownloadURL(storageRef);

    setGallery([...gallery, url]); // Add new image URL to gallery
    setNewImageFile(null);
    setFormLoading(false);
  };

  const handleSaveGallery = async () => {
    setFormLoading(true);
    const docRef = doc(db, "Info", "Images");
    await updateDoc(docRef, { Gallery: gallery });
    setFormLoading(false);
    alert("Gallery updated successfully!");
  };

  const handleRemoveImage = (index) => {
    setImageToRemove(index); // Store index of image to remove
    setShowConfirmModal(true); // Show confirmation modal
  };

  const confirmRemoveImage = () => {
    const newGallery = [...gallery];
    newGallery.splice(imageToRemove, 1); // Remove image at the specific index
    setGallery(newGallery);
    setImageToRemove(null);
    setShowConfirmModal(false);
  };

  const cancelRemoveImage = () => {
    setImageToRemove(null);
    setShowConfirmModal(false);
  };

  return (
    <div className="min-h-screen p-4">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-full max-w-lg mx-auto">
          <h2 className="text-center mb-4">Edit Gallery Images</h2>

          {gallery.map((url, index) => (
            <div key={index} className="mb-3">
              <div className="mb-2">
                <img src={url} alt={`gallery-${index}`} className="w-full h-40 object-cover rounded" />
              </div>
              <Form.Control
                type="text"
                value={url}
                onChange={(e) => handleImageChange(e, index)}
                className="mb-2"
              />
              <Button variant="danger" onClick={() => handleRemoveImage(index)}>
                Remove
              </Button>
            </div>
          ))}

          <Form.Group className="mb-3">
            <Form.Label>Add New Image</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setNewImageFile(e.target.files[0])}
            />
            <Button
              className="mt-2"
              onClick={handleAddNewImage}
              disabled={formLoading}
            >
              {formLoading ? "Uploading..." : "Add Image"}
            </Button>
          </Form.Group>

          <Button
            variant="primary"
            onClick={handleSaveGallery}
            disabled={formLoading}
          >
            {formLoading ? "Saving..." : "Save Changes"}
          </Button>

          {/* Confirmation Modal */}
          <Modal show={showConfirmModal} onHide={cancelRemoveImage}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm Image Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to remove this image?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={cancelRemoveImage}>
                Cancel
              </Button>
              <Button variant="danger" onClick={confirmRemoveImage}>
                Confirm Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
}
