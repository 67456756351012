import React, { useState } from "react";
import EditRooms from "../AdminPages/Editrooms";
import BookingCheck from "../AdminPages/BookingCheck";
import EditImages from "../AdminPages/EditImages";
import EditContactInfo from "../AdminPages/EditContactInfo";
import AdminLogin from "../AdminPages/AdminLogin"; // Import the AdminLogin component

export default function AdminHome() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for drawer toggle
  const [activeComponent, setActiveComponent] = useState("Booking"); // State to manage active component
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to manage login status

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen); // Toggle the drawer
  };

  const handleNavClick = (component) => {
    setActiveComponent(component); // Set the active component based on the navbar click
    setIsDrawerOpen(false); // Close the drawer after selection
  };

  const handleLogin = (status) => {
    setIsLoggedIn(status); // Update the login status
  };

  if (!isLoggedIn) {
    return <AdminLogin onLogin={handleLogin} />; // Show login if not logged in
  }

  return (
    <>
      <nav className="bg-gray-100 h-20 relative flex justify-between items-center text-black lg:justify-evenly z-10">
        {/* Logo */}
        <div className="text-black font-bold cursor-default select-none uppercase text-nowrap flex items-center justify-center">
          <h1 className="text-[4vh] m-0">ADMIN</h1>
        </div>

        {/* Drawer for mobile screens */}
        <div
          className={`flex lg:flex-row flex-col gap-24 py-12 px-5 font-bold bg-gray-100 text-sm list-none h-auto w-full absolute top-20 transition-all ease-in-out duration-300 ${
            isDrawerOpen ? "left-0 block" : "-left-full"
          } lg:flex-row lg:static lg:py-0 lg:w-auto lg:gap-10`}
        >
          <li className="transition-all ease-in-out content-center hover:scale-105 uppercase">
            <a
              className="decoration-transparent text-neutral-800 font-bold text-[1.8vh] text-nowrap"
              href="#"
              onClick={() => handleNavClick("EditRooms")}
            >
              Edit Rooms
            </a>
          </li>
          <li className="transition-all ease-in-out content-center hover:scale-105 uppercase">
            <a
              className="decoration-transparent text-neutral-800 font-bold text-[1.8vh] text-nowrap"
              href="#"
              onClick={() => handleNavClick("Booking")}
            >
              Bookings
            </a>
          </li>
          <li className="transition-all ease-in-out content-center hover:scale-105 uppercase">
            <a
              className="decoration-transparent text-neutral-800 font-bold text-[1.8vh] text-nowrap"
              href="#"
              onClick={() => handleNavClick("EditImages")}
            >
              Edit Images
            </a>
          </li>
          <li className="transition-all ease-in-out content-center hover:scale-105 uppercase">
            <a
              className="decoration-transparent text-neutral-800 font-bold text-[1.8vh] text-nowrap"
              href="#"
              onClick={() => handleNavClick("EditContactInfo")}
            >
              Edit Contact Info
            </a>
          </li>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="bg-green-500 w-12 h-12 text-2xl lg:hidden me-[2vw] text-white"
          onClick={toggleDrawer}
        >
          {isDrawerOpen ? (
            <i className="fa-solid fa-xmark"></i> // Close icon
          ) : (
            <i className="fa-solid fa-bars"></i> // Menu icon
          )}
        </button>
      </nav>

      {/* Render the active component */}
      {activeComponent === "Booking" && <BookingCheck />}
      {activeComponent === "EditRooms" && <EditRooms />}
      {activeComponent === "EditImages" && <EditImages />}
      {activeComponent === "EditContactInfo" && <EditContactInfo />}
    </>
  );
}
