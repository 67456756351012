import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import db from "../firebaseConfig";
import RoomCard from "./RoomCard";

const RoomDetails = () => {
  const { roomid } = useParams(); // Get roomid from URL
  const [roomData, setRoomData] = useState(null);
  const [reviews, setReviews] = useState([]);

  const calculateAverageRating = (ratings) => {
    if (!ratings || ratings.length === 0) return null;
    const sum = ratings.reduce((acc, rating) => acc + rating, 0);
    return (sum / ratings.length).toFixed(1);
  };

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const roomDoc = await getDoc(doc(db, "Rooms", roomid));
        if (roomDoc.exists()) {
          const data = roomDoc.data();
          setRoomData(data);
          setReviews(data.Review || []); // Fetch reviews if available
        } else {
          console.log("Room not found");
        }
      } catch (error) {
        console.error("Error fetching room details:", error);
      }
    };

    fetchRoomDetails();
  }, [roomid]);

  return (
    <div className="overflow-hidden">
      {roomData ? (
        <>
          {/* Pass roomData to RoomCard in the desired format */}
          <div key={roomid} className="mb-[10vh]">
            <RoomCard
              roomid={roomid}
              image={roomData.image}
              name={roomData.name}
              ratings={calculateAverageRating(roomData.Rating) || "No Ratings Available"}
              price={roomData.price}
              offeredPrice={roomData.offeredPrice}
              size={roomData.size}
              capacity={roomData.capacity}
              services={roomData.services}
              beds={roomData.beds}
              nrooms={roomData.nrooms}
            />
          </div>

          <h2 className="text-neutral-900 text-[3vh] uppercase text-center border-teal-500 border-1 mx-[6vw] py-1 rounded-md lg:mx-[20vw]">
            Review/Feedback
          </h2>
          {reviews.length > 0 ? (
            <ul className=" flex flex-col px-[3vw]">
              {reviews.map((review, index) => {
                const [user, text] = review.split(":"); // Split the string into username and review text
                return (
                  <li key={index} className=" p-[2vh] lg:mx-[20vw]">
                    <div className="bg-sky-50 border-sky-200 border-1 p-[2vh] rounded-md">
                      <h3 className="text-[3vh] text-neutral-800">{user.trim()}</h3>
                      <p className="text-[2vh] text-neutral-700">{text.trim()}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>No reviews available.</p>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default RoomDetails;
