import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db from "../firebaseConfig"; // Adjust the path as necessary
import { Spinner, Modal, Button, Form } from "react-bootstrap";

const MyRooms = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBooking, setSelectedBooking] = useState(null); // For modal state
  const [showModal, setShowModal] = useState(false); // To control modal visibility
  const [showFeedbackModal, setShowFeedbackModal] = useState(false); // To control feedback modal
  const [showThankYouModal, setShowThankYouModal] = useState(false); // To control thank you modal
  const [rating, setRating] = useState(1);
  const [review, setReview] = useState("");
  const auth = getAuth();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const user = localStorage.getItem("uid");
        if (!user) {
          setLoading(false);
          return;
        }

        // Fetch the user's myrooms array from the Registration collection
        const userDocRef = doc(db, "Registration", user);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const myrooms = userDoc.data().myrooms || [];

          // Fetch all booking details using the myrooms array
          const bookingPromises = myrooms.map(async (bookingId) => {
            const bookingDocRef = doc(db, "Bookings", bookingId);
            const bookingDoc = await getDoc(bookingDocRef);

            if (bookingDoc.exists()) {
              const bookingData = bookingDoc.data();

              // Fetch room details for each booking
              const roomDocRef = doc(db, "Rooms", bookingData.roomid);
              const roomDoc = await getDoc(roomDocRef);

              if (roomDoc.exists()) {
                const roomData = roomDoc.data();
                return { ...bookingData, ...roomData, roomid: bookingData.roomid, bookingId: bookingDoc.id };
              }
            }

            return null;
          });

          const bookingResults = await Promise.all(bookingPromises);
          const validBookings = bookingResults.filter((booking) => booking !== null);
          setBookings(validBookings);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching bookings:", error);
        setLoading(false);
      }
    };

    fetchBookings();
  }, [auth]);

  const handleShowModal = (booking) => {
    setSelectedBooking(booking);
    setShowModal(true);
  };

  const handleShowFeedbackModal = (booking) => {
    setSelectedBooking(booking);
    setShowFeedbackModal(true);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleCloseFeedbackModal = () => setShowFeedbackModal(false);
  const handleCloseThankYouModal = () => {
    setShowThankYouModal(false);
    window.location.reload(); // Reload the page when closing the thank you modal
  };

  const handleSubmitReview = async () => {
    if (selectedBooking) {
      try {
        const roomDocRef = doc(db, "Rooms", selectedBooking.roomid);
        const bookingDocRef = doc(db, "Bookings", selectedBooking.bookingId);
  
        // Fetch existing room data
        const roomDoc = await getDoc(roomDocRef);
        if (roomDoc.exists()) {
          const roomData = roomDoc.data();
  
          // Create new arrays with the existing ratings and the new rating
          const updatedRatings = [...(roomData.Rating || []), rating];
          const updatedReviews = [...(roomData.Review || []), `${selectedBooking.fullName}: ${review}`];
  
          // Update the Rating and Review arrays in the Rooms collection
          await updateDoc(roomDocRef, {
            Rating: updatedRatings,
            Review: updatedReviews,
          });
  
          // Mark the booking as feedbacked
          await updateDoc(bookingDocRef, {
            feedbacked: true,
          });
  
          // Show thank you modal
          setShowThankYouModal(true);
  
          // Clear the form and close modal
          setRating(1);
          setReview("");
          handleCloseFeedbackModal();
        }
      } catch (error) {
        console.error("Error submitting review:", error);
      }
    }
  };
  

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <>
      {/* Grid Layout Container */}
      <div className="w-full grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 min-h-[65vh]">
        {bookings.length > 0 ? (
          bookings.map((booking, index) => (
            <div key={index} className="w-full p-2">
              <div className="bg-white rounded-lg overflow-hidden">
                <img className="rounded-md m-1 w-full h-80 object-cover" src={booking.image} alt={booking.name} />
                <div className="ms-[2vw] p-4">
                  <h1 className="text-neutral-900 font-bold text-[3vh] tracking-wider uppercase">
                    {booking.name}
                  </h1>
                  <p className="font-medium text-neutral-800">
                    <strong className="text-neutral-600 font-normal">Name:</strong> {booking.fullName}<br />
                    <strong className="text-neutral-600 font-normal">Check-In Date:</strong> {booking.checkInDate}<br />
                    <strong className="text-neutral-600 font-normal">Check-Out Date:</strong> {booking.checkOutDate}<br />
                    {booking.Status === "Checked Out" && !booking.feedbacked && (
                      <button
                        className="float-end mb-4 px-[1vw] h-9 rounded-md ms-2 bg-green-500 text-white text-[2vh]"
                        onClick={() => handleShowFeedbackModal(booking)}
                      >
                        Feedback
                      </button>
                    )}
                    <button
                      className="float-end mb-4 w-9 h-9 rounded-full bg-sky-500 text-white text-[2vh]"
                      onClick={() => handleShowModal(booking)}
                    >
                      i
                    </button>
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="nobookings col-span-full text-center content-center">No bookings found.</div>
        )}
      </div>

      {/* Modal for detailed booking info */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Booking Details</Modal.Title>
        </Modal.Header>
        {selectedBooking && (
          <Modal.Body>
            <strong>Total:</strong> ₹{selectedBooking.calculatedprice + selectedBooking.calculatedprice * 0.06}<br />
            <strong>Beds:</strong> {selectedBooking.beds}<br />
            <strong>Total Person:</strong> {selectedBooking.totalPersons} persons<br />
            <strong>Size:</strong> {selectedBooking.size} sq ft<br />
            <strong>Services:</strong> {selectedBooking.services.join(", ")}<br />
            {selectedBooking.idProofUrl && (
              <a href={selectedBooking.idProofUrl} target="_blank" rel="noopener noreferrer">
                View ID Proof
              </a>
            )}
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Feedback */}
      <Modal show={showFeedbackModal} onHide={handleCloseFeedbackModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Give Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group>
  <Form.Label>Rating</Form.Label>
  <div className="flex space-x-2">
    {[1, 2, 3, 4, 5].map((value) => (
      <label key={value} className={`cursor-pointer p-2 w-10 h-10 flex items-center justify-center border-2 rounded-md ${rating === value ? 'bg-green-300 text-green-700 border-green-500' : 'bg-gray-200 text-black'}`}>
        <input
          type="radio"
          name="rating"
          value={value}
          checked={rating === value}
          onChange={(e) => setRating(Number(e.target.value))}
          className="hidden" // Hide the default radio button circle
        />
        {value} {/* Display the number as a square button */}
      </label>
    ))}
  </div>
</Form.Group>

            <Form.Group>
              <Form.Label>Review</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={review}
                onChange={(e) => setReview(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFeedbackModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmitReview}>
            Submit Review
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Thank You Modal */}
      <Modal show={showThankYouModal} onHide={handleCloseThankYouModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Thank you for your valuable feedback!</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseThankYouModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyRooms;
