import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; 

function Navbar() {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for drawer toggle
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    setisLoggedIn(loggedInStatus === "true");
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
    setShowLogoutModal(false);
  };

  const handleLogin = () => {
    navigate("/Login");
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen); // Toggle the drawer
  };

  return (
    <div>
      <nav className="bg-gray-100 h-20 relative flex justify-between items-center text-black lg:justify-evenly z-50">
        {/* Logo */}
        <div className="text-black font-bold cursor-default select-none uppercase text-nowrap fliex items-center justify-center">
          <h1 className="text-[4vh] m-0">Hotel Rainbow</h1>
        </div>

        {/* Drawer for mobile screens */}
        <div
          className={`flex lg:flex-row lg:flex flex-col gap-24 py-12 px-5 font-bold bg-gray-100 text-sm list-none h-auto w-full absolute top-20 transition-all ease-in-out duration-300  ${
            isDrawerOpen ? "left-0 block" : "-left-full"
          } lg:flex-row lg:static lg:py-0 lg:w-auto lg:gap-10`}
        >
          <li className="transition-all  ease-in-out  content-center decot hover:scale-105 uppercase">
            <a className="decoration-transparent text-neutral-800 font-bold text-[1.8vh] text-nowrap" href="/">Home</a>
          </li>
          <li className="transition-all ease-in-out  content-center decot hover:scale-105 uppercase">
            <a className="decoration-transparent text-neutral-800 font-bold text-[1.8vh] text-nowrap" href="/rooms">Rooms</a>
          </li>
          <li className="transition-all ease-in-out  content-center decot hover:scale-105 uppercase">
            <a className="decoration-transparent text-neutral-800 font-bold text-[1.8vh] text-nowrap" href="/about">About Us</a>
          </li>
          <li className="transition-all ease-in-out  content-center decot hover:scale-105 uppercase">
            <a className="decoration-transparent text-neutral-800 font-bold text-[1.8vh] text-nowrap" href="/contact">Contact</a>
          </li>
          {isLoggedIn && (
            <li className="transition-all ease-in-out  content-center decot hover:scale-105 uppercase">
              <a className="decoration-transparent text-neutral-800 font-bold text-[1.8vh] text-nowrap" href="/MyRooms">My Rooms</a>
            </li>
          )}
          {/* Login/Logout and Book Now */}
        <div className="flex flex-row  gap-3">
          {isLoggedIn ? (
            <button
              className="bg-red-500 text-white py-2 px-4 rounded hover:scale-110 transition-all ease-in-out"
              onClick={() => setShowLogoutModal(true)}
            >
              Logout
            </button>
          ) : (
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded hover:scale-110 transition-all ease-in-out"
              onClick={handleLogin}
            >
              Login
            </button>
          )}
          <a
            href="/rooms"
            className="bg-teal-500 text-white py-2 px-4 rounded hover:scale-110 transition-all ease-in-out decoration-transparent hidden lg:block"
          >
            Book Now
            <i className="fas fa-arrow-right ml-2"></i>
          </a>
        </div>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="bg-green-500 w-12 h-12 text-2xl lg:hidden me-[2vw] text-white"
          onClick={toggleDrawer}
        >
          {isDrawerOpen ? (
            <i className="fa-solid fa-xmark"></i> // Close icon
          ) : (
            <i className="fa-solid fa-bars"></i> // Menu icon
          )}
        </button>

        
      </nav>

      {/* Logout Confirmation Modal */}
      <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Navbar;
