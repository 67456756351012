import React from "react";
function About() {
  return (
    <>
      <div className="bg-about h-[30vh] bg-cover bg-fixed bg-bottom content-center text-center font-bold text-[7vh] text-white flex items-center justify-center">
        ABOUT US
      </div>
      <div className="flex flex-col md:flex-row items-center md:gap-4 justify-center text-center mx-[5vw] lg:mx-[10vw] my-[10vh] ">
        <div className="w-full h-[30vh] lg:h-[40vh] rounded-md  relative overflow-hidden">
        <div className="bg-dashboard w-full h-[30vh] lg:h-[40vh] bg-cover rounded-md transition-all absolute hover:scale-110"></div>
        </div>
        <div className="flex flex-col my-[3vh] text-neutral-900 sm:gap-3 lg:gap-9 md:w-[100vw]">
          <h2 className="text-teal-500 text-[2.5vh] md:text-start md:ms-[2vw]">
            TESTIMONIALS
          </h2>
          <h1 className="text-[4vh] font-semibold md:text-start md:ms-[2vw]">
            20 Years Of Experience
          </h1>
          <h5 className="text-[1.7vh] pe-[5vw] text-neutral-600 md:text-start md:ms-[2vw]">
          With over 20 years of experience, Hotel Rainbow has built a legacy of outstanding service and hospitality. Our guests' satisfaction is at the heart of everything we do. From business travelers to families on vacation, we've had the privilege of hosting guests from all walks of life, and their kind
          </h5>
          <h5 className="text-[1.7vh] pe-[5vw] text-neutral-600 md:text-start md:ms-[2vw]">
          words reflect our commitment to creating unforgettable experiences. Our dedicated team, luxurious accommodations, and attention to detail are what keep our guests coming back year after year. We’re proud to be a trusted choice for comfort and quality for two decades!
          </h5>
        </div>
      </div>

      <div className="bg-about h-[80vh] flex flex-col bg-cover bg-fixed justify-evenly items-center">
        <h2 className="text-white font-bold lg:text-[6vh] z-10 text-center text-[5vh] ">Our Hotel & Rooms</h2>
        <div className="backdrop-blur-md rounded-md overflow-hidden z-10">
          <div className="w-[80vw] h-[40vh] flex items-center justify-evenly flex-col lg:flex-row lg:h[30vh] ">
            <h1 className="text-teal-500 lg:text-[4vh] text-[3vh] w-[50vw] tracking-wide lg:mx-0 mx-auto">
              Contact Us Now <span className="text-white">!</span><br />
              <span className="lg:text-[3vh] text-[2vh] text-white font-normal tracking-normal">
                Contact (+12) 345-678-9999 to book directly or for advice
              </span>
            </h1>
            <div className="bg-teal-500 px-[5vw] py-[2vh] me-[5vh] content-center text-center rounded-md hover:scale-105 transition-all ease-in-out">
              <a
              className="text-white decoration-transparent font-bold text-[2vh]"
              href="/Contact">CONTACT US</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
