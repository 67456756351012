import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import db from "../firebaseConfig"; // Adjust this path based on your setup
import { ToastContainer, toast } from "react-toastify"; // Import toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

const EditContactInfo = () => {
  const [contactInfo, setContactInfo] = useState({
    Phone: "",
    Email: "",
    Address: "",
    Facebook: "",
    Instagram: "",
    Whatsapp: "",
  });

  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(""); // State to manage error messages

  useEffect(() => {
    const fetchContactInfo = async () => {
      const docRef = doc(db, "Info", "Contact");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setContactInfo(docSnap.data());
        setLoading(false);
      } else {
        setError("No such document!");
        setLoading(false);
      }
    };

    fetchContactInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "Info", "Contact");
      await updateDoc(docRef, contactInfo);
      toast.success("Contact information updated successfully!"); // Show success toast
    } catch (error) {
      setError("Error updating document: " + error.message);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="flex flex-col items-center justify-evenly min-h-[90vh]">
      <h2 className="text-[4vh]">Edit Contact Information</h2>
      {error && <p className="text-danger">{error}</p>}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center justify-evenly h-[50vh]"
      >
        <div className="w-[90vw] md:w-[50vw] lg:w-[30vw] flex items-center gap-[5vw] justify-center">
          <label className="text-[2vh] text-neutral-900 w-[20%]">Phone:</label>
          <input
            className="bg-teal-100 ps-[1vw] py-[.5vh] rounded-md"
            type="text"
            name="Phone"
            value={contactInfo.Phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="w-[90vw] md:w-[50vw] lg:w-[30vw] flex items-center gap-[5vw] justify-center">
          <label className="text-[2vh] text-neutral-900 w-[20%]">Email:</label>
          <input
            className="bg-teal-100 ps-[1vw] py-[.5vh] rounded-md"
            type="email"
            name="Email"
            value={contactInfo.Email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="w-[90vw] md:w-[50vw] lg:w-[30vw] flex items-center gap-[5vw] justify-center">
          <label className="text-[2vh] text-neutral-900 w-[20%]">
            Address:
          </label>
          <input
            className="bg-teal-100 ps-[1vw] py-[.5vh] rounded-md"
            type="text"
            name="Address"
            value={contactInfo.Address}
            onChange={handleChange}
            required
          />
        </div>
        <div className="w-[90vw] md:w-[50vw] lg:w-[30vw] flex items-center gap-[5vw] justify-center">
          <label className="text-[2vh] text-neutral-900 w-[20%]">
            Facebook:
          </label>
          <input
            className="bg-teal-100 ps-[1vw] py-[.5vh] rounded-md"
            type="text"
            name="Facebook"
            value={contactInfo.Facebook}
            onChange={handleChange}
          />
        </div>
        <div className="w-[90vw] md:w-[50vw] lg:w-[30vw] flex items-center gap-[5vw] justify-center">
          <label className="text-[2vh] text-neutral-900 w-[20%]">
            Instagram:
          </label>
          <input
            className="bg-teal-100 ps-[1vw] py-[.5vh] rounded-md"
            type="text"
            name="Instagram"
            value={contactInfo.Instagram}
            onChange={handleChange}
          />
        </div>
        <div className="w-[90vw] md:w-[50vw] lg:w-[30vw] flex items-center gap-[5vw] justify-center">
          <label className="text-[2vh] text-neutral-900 w-[20%]">
            Whatsapp:
          </label>
          <input
            className="bg-teal-100 ps-[1vw] py-[.5vh] rounded-md"
            type="text"
            name="Whatsapp"
            value={contactInfo.Whatsapp}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="bg-teal-500 w-[80%] py-[1vh] rounded-md text-white font-bold text-[2vh] tracking-wider hover:scale-105 transition-all">
          Update
        </button>
      </form>
      <ToastContainer /> {/* Add ToastContainer here */}
    </div>
  );
};

export default EditContactInfo;
